// @flow

import * as React from 'react'
import {Button, LightBox} from '../../../ui-components'

import CUSTOMER_VIDEO from './assets/video-screen.png'

import * as styles from './CustomerSpotlight.module.css'

const CUSTOMER_QUOTE = `"I've done I don't know how many hundreds of minipreps in my life. I don't want to do another 3,000."`

const ButtonProps = {
  children: 'see all stories',
  className: styles.button,
  Component: 'a',
  linkOutProps: {
    url: 'https://blog.opentrons.com/tag/community/',
    gtm: {
      action: 'click',
      category: 'b-hp',
      label: 'community-all',
    },
  },
}

const NICK_VIDEO = `https://player.vimeo.com/video/303374891`

export type CustomerSpotlightTypes = {
  whiteBg?: boolean,
}

export default function CustomerSpotlight(props: CustomerSpotlightTypes) {
  const [videoOpen, setVideoOpen] = React.useState(false)
  const {whiteBg} = props
  const VideoButtonProps = {
    children: (
      <span>
        <span className={styles.play_button} />
        watch video
      </span>
    ),
    className: styles.video_button,
    onClick: () => setVideoOpen(true),
    gtmAction: 'click',
    gtmCategory: 'b-hp',
    gtmLabel: 'community-video1',
  }
  return (
    <div className={whiteBg ? null : styles.bg_color}>
      <div className={styles.container}>
        <div className={styles.spotlight_video}>
          <h3 className={styles.h3}>Customer spotlight</h3>
          <h5 className={styles.customer_quote}>{CUSTOMER_QUOTE}</h5>
          <h5 className={styles.h5}>Nick Emery, DAMP of Boston University</h5>
          <Button {...ButtonProps} />
        </div>
        <div className={styles.customer_video}>
          <img src={CUSTOMER_VIDEO} alt="Customer Feedback" />
          <Button {...VideoButtonProps} />
        </div>
      </div>
      {videoOpen && (
        <LightBox
          videoSrc={NICK_VIDEO}
          onCloseClick={() => setVideoOpen(false)}
        />
      )}
    </div>
  )
}
