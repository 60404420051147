import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Normalization from '../components/Normalization'

const NormalizationPage = () => (
  <Page>
    <SEO
      title="Automated DNA Normalization Made Easy | Starting at under $10,000"
      description="DNA normalization is a key component of many genomic and proteomic applications, such as NGS library prep and mass spectrometry sample prep."
    />
    <Normalization />
  </Page>
)

export default NormalizationPage
