// extracted by mini-css-extract-plugin
export var bg_color = "CustomerSpotlight-module--bg_color--NJF1u";
export var container = "CustomerSpotlight-module--container--htXE1";
export var p = "CustomerSpotlight-module--p--y7OcE";
export var h3 = "CustomerSpotlight-module--h3--s4Euh";
export var h5 = "CustomerSpotlight-module--h5--eeuhc";
export var customer_quote = "CustomerSpotlight-module--customer_quote--2R8iG";
export var button = "CustomerSpotlight-module--button--shRIi";
export var video_button = "CustomerSpotlight-module--video_button--WY88U";
export var customer_video = "CustomerSpotlight-module--customer_video--9n7vq";
export var play_button = "CustomerSpotlight-module--play_button---O25i";
export var spotlight_video = "CustomerSpotlight-module--spotlight_video--OT1vs";