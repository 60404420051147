import * as React from 'react'
import {Link} from 'gatsby'

import CustomerSpotlight from '../homepage/CustomerSpotlight'

import {
  Overview,
  AppLink,
  Benefit,
  Instructions,
  OpentronsMaterial,
  Notes,
  Resources,
  Related,
  StickyMenu,
  VideoHero,
  LightBox,
  Hero,
} from '../../ui-components'

import {
  OT_ROBOT_URL,
  NORMALIZATION_PROTOCOL_URL,
  WELL_MIRCOPLATES_URL,
  RESERVOIR_URL,
  BENEFIT_DATA,
  WORKFLOW_DATA,
  MATERIAL_DATA,
  LABWARE_URL,
  RESOURCE_NOTE_DATA,
  RELATED_CONTENT_DATA,
  VIDEO_TITLE,
  USER_VIDEO,
  OVERVIEW_DATA,
  CSV_FILE,
} from './normalization-data'

import HERO_VIDEO from './assets/Normalization_video_screenshot@2x.jpg'

import * as styles from './Normalization.module.css'

export const CONTENT_INFO = (
  <p className={styles.p}>
    Concentration normalization is a key component of many genomic and proteomic
    applications, such as NGS library prep and mass spectrometry sample prep.
    This process often requires working with 96 or more samples, all needing
    unique volumes of diluent to be brought to the correct “normalized”
    concentration. It’s a high-throughput and complex application, and can be
    cumbersome, labor-intensive, and error-prone to do by hand. This makes it
    ideal for automation. <br />
    <br />
    A robot can more easily, quickly, and accurately read from a file and
    transfer the correct volume to each sample than a human. Automating this
    process means you don’t have to worry about errors in volume calculation or
    dispensing into the wrong location.
    <br />
    <br />
    <AppLink url={OT_ROBOT_URL}>
      Using our OT-2 liquid handling robot
    </AppLink>{' '}
    and{' '}
    <AppLink url={NORMALIZATION_PROTOCOL_URL}>Normalization protocol</AppLink>,
    you can automate your normalization steps simply and efficiently.
  </p>
)

const MENU_DATA = {
  menuData: {
    links: [
      'overview',
      'benefits',
      'workflow',
      'opentrons materials',
      'notes',
      'resources',
    ],
    url: '/automated-dna-normalization',
    workflow: 'Interested in automating your DNA normalization?',
  },
  className: styles.menu,
}

export const NOTE_DATA = [
  {
    name: 'Labware',
    note: (
      <p>
        The OT-2 supports various labware for normalization. While the labware
        you use may vary, we strongly recommend using one of our supported
        microplates and reservoirs with this protocol because that’s what we
        used to optimize and verify it. For more information on supported
        labware, please see our{' '}
        <AppLink url={LABWARE_URL}>Labware Library</AppLink>.
      </p>
    ),
  },
  {
    name: 'Pipettes',
    note: (
      <p>
        Ensure that your OT-2 is fitted with single-channel pipettes for the
        volumes you will need to work with. Only single-channel pipettes are
        supported for this method. Please review our Opentrons Pipette White
        Papers linked below for more information on our pipettes and pipetting
        volumes as low as 1 µL.
      </p>
    ),
  },
]

// eslint-disable-next-line no-unused-vars
const MATERIAL_BULLET_POINT_DATA = [
  <p key={'1'}>
    <AppLink url={WELL_MIRCOPLATES_URL}>96-or 384-well microplates</AppLink>
  </p>,
  <p key={'2'}>
    <AppLink url={RESERVOIR_URL}>Automation-friendly reservoir</AppLink>
  </p>,
  <p key={'3'}>
    <AppLink url={CSV_FILE}>CSV file</AppLink>
  </p>,
  <p key={'4'}>
    <AppLink url={NORMALIZATION_PROTOCOL_URL}>Normalization protocol</AppLink>
  </p>,
]

const relatedButtonProps = [
  {
    children: 'Nucleic Acid Purification',
    Component: Link,
    to: '/nucleic-acid-cleanup',
    outline: true,
  },
  {
    children: 'PCR Sample Prep',
    Component: Link,
    to: '/pcr-sample-prep',
    outline: true,
  },
  {
    children: 'Cherry Picking',
    Component: Link,
    to: '/automated-cherrypicking',
    outline: true,
  },
]

const TITLE = 'Automated DNA Normalization'

export default function Normalization() {
  const [videoOpen, setVideoOpen] = React.useState(false)
  return (
    <>
      <Hero
        application={TITLE}
        backgroundClassName={styles.background_image}
        h2ClassName={styles.hero_h2}
        textClassName={styles.hero_text}
      />
      <div className={styles.container}>
        <StickyMenu {...MENU_DATA} />
        <div className={styles.content_container}>
          <VideoHero
            onClick={() => setVideoOpen(true)}
            videoStill={HERO_VIDEO}
            title={VIDEO_TITLE}
          />
          <Overview content={CONTENT_INFO} {...OVERVIEW_DATA} />
          <Benefit {...BENEFIT_DATA} />
          <Instructions workflowItems={WORKFLOW_DATA} />
          <OpentronsMaterial
            materialItems={MATERIAL_DATA}
            /* bulletItems={MATERIAL_BULLET_POINT_DATA} TODO uncomment this once the data is available */
          />
          <Notes noteItems={NOTE_DATA} />
          <Resources ResourceData={RESOURCE_NOTE_DATA} />
        </div>
      </div>
      {videoOpen && (
        <LightBox
          videoSrc={USER_VIDEO}
          onCloseClick={() => setVideoOpen(false)}
        />
      )}
      <Related
        relatedContent={RELATED_CONTENT_DATA}
        buttons={relatedButtonProps}
      />
      <CustomerSpotlight whiteBg={true} />
    </>
  )
}
