export const PRICES = {
  OT2: '$6,500',
  SingleChannelPipette: '$1,250',
  MultiChannelPipette: '$2,000',
  PipetteP20SGEN2: '$1,250',
  PipetteP300MGEN2: '$2,000',
  TemperatureModule: '$3,750',
  MagneticModule: '$3,750',
  ThermocyclerModule: '$9,750',
  HepaModule: '$6,250',
  // worskstation cost savings, first is normal price, second is savings price
  NgsLibraryPrepWorkstation: ['$25,019', '$19,750'],
  NgsWorkstation: ['$31,960', '$27,000'],
  PcrWorkstation: ['$31,210', '$26,500'],
  PcrWorkstationSecondary: ['$21,460', '$19,000'],
  NucleicAcidExtractionWorkstation: ['$21,110', '$18,500'],
  NucleicAcidExtractionWorkstationSecondary: ['$17,360', '$15,500'],
  ProteinPurificationChromatographyWorkstation: ['$12,730', '$12,000'],
  ProteinPurificationMagneticBeadsWorkstation: ['$20,972', '$19,000'],
}

export const SAVINGS = {
  NgsLibraryPrepWorkstation: '21%',
  NgsWorkstation: '16%',
  PcrWorkstation: '11-16%',
  NucleicAcidExtractionWorkstation: '11-12%',
  ProteinPurificationChromatographyWorkstation: '6%',
  ProteinPurificationMagneticBeadsWorkstation: '9%',
}
